import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlayCircle,
  faUniversity,
  faCode,
  faUsers,
  faChartLine,
  faClipboardList,
} from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import CountUp from 'react-countup';
import './about.css';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import Bg from '../components/parallax';
const icons = [
  faPlayCircle,
  faUniversity,
  faCode,
  faUsers,
  faChartLine,
  faClipboardList,
];
class About extends Component {
  componentDidMount() {
    AOS.init({
      duration: 1000,
    });
  }

  heading = {
    title: 'About SDS',
    description:
      'SDS is a team of students who take up projects with the aim of resolving technical needs of the college',
  };

  infos = [
    [
      {
        title: 'THE START',
        description:
          'The club was founded by a group of final year computer engineering students on April 26th, 2017',
        icon: 'faPlayCircle',
      },
      {
        title: 'THE AUTHORITY',
        description:
          'SDS is the only club in COEP for software development which is officially approved by Gymkhana',
        icon: 'faUniversity',
      },
    ],
    [
      {
        title: 'THE CODE',
        description:
          'Our team is proficient in C, JAVA, LAMP Stack, MERN Stack, Drupal and Full stack web development',
        icon: 'faCode',
      },
      {
        title: 'THE TEAM',
        description:
          'The core team is responsible for overall functioning of the club and consists of 8 students for the year 2024-25',
        icon: 'faUsers',
      },
    ],
    [
      {
        title: 'THE ACTIVITIES',
        description:
          'SDS hosts numerous events including workshops, technical talks, hackathons and other technical competitions throughout the year',
        icon: 'faClipboardList',
      },
      {
        title: 'THE VISION',
        description:
          'To promote in-house development of technological solutions and provide a platform for software development to students',
        icon: 'faChartLine',
      },
    ],
  ];

  datas = [
    { svgname: 'members', count: '30', title: 'Active Members' },
    { svgname: 'project', count: '22', title: 'Completed Projects' },
    { svgname: 'code', count: '51391', title: 'Lines of Code' },
  ];

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <Bg />
        <div className="main-content">
          <div id="main">
            <div className="_heading" data-aos="fade-down">
              <div className="title">{this.heading.title}</div>
              <div className="description">{this.heading.description}</div>
            </div>

            <div className="content">
              <div className="container">
                <div className="row">
                  {this.infos.map((info, key) => {
                    return (
                      <div className="col-md-12" key={key}>
                        <div className="main-timeline">
                          {info.map((data, index) => {
                            return (
                              <div
                                className="timeline"
                                key={2 * key + index}
                                data-aos={
                                  index ? 'fade-up-left' : 'fade-up-right'
                                }
                              >
                                <div className="timeline-content">
                                  <div className="timeline-icon">
                                    <FontAwesomeIcon
                                      icon={icons[2 * key + index]}
                                    />
                                  </div>
                                  <h3 className="title">{data.title}</h3>
                                  <p className="description">
                                    {data.description}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="datas">
              <div className="container">
                <div className="row">
                  {this.datas.map((data, key) => (
                    <div className="col-md-4" key={key}>
                      <div className="info" data-aos="flip-up">
                        <div className="info-icon">
                          <img
                            src={'/icons/' + data.svgname + '.png'}
                            alt="count"
                          />
                        </div>
                        <div className="info-count">
                          <CountUp end={data.count} delay={2} />
                        </div>
                        <div className="info-title">{data.title}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="home__footer">
            <Footer />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default About;
